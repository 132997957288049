const FAQData = {
    "industrialFAQ":[
         {
           question: "What is a Real Estate CRM?",
            answer: "It helps you manage your real estate business from one simple platform- witt features like contact management, call recording, auto-dialer, reminder, integration with popular real estate websites like Housing.com, Magicbricks, 99Acres, and Olx, and automations to get rid of repetitive tasks.",
        },
        {
            question: "What is a Real Estate CRM?",
             answer: "It helps you manage your real estate business from one simple platform- witt features like contact management, call recording, auto-dialer, reminder, integration with popular real estate websites like Housing.com, Magicbricks, 99Acres, and Olx, and automations to get rid of repetitive tasks.",
         },
         {
            question: "What is a Real Estate CRM?",
             answer: "It helps you manage your real estate business from one simple platform- witt features like contact management, call recording, auto-dialer, reminder, integration with popular real estate websites like Housing.com, Magicbricks, 99Acres, and Olx, and automations to get rid of repetitive tasks.",
         },
         {
            question: "What is a Real Estate CRM?",
             answer: "It helps you manage your real estate business from one simple platform- witt features like contact management, call recording, auto-dialer, reminder, integration with popular real estate websites like Housing.com, Magicbricks, 99Acres, and Olx, and automations to get rid of repetitive tasks.",
         },
         {
            question: "What is a Real Estate CRM?",
             answer: "It helps you manage your real estate business from one simple platform- witt features like contact management, call recording, auto-dialer, reminder, integration with popular real estate websites like Housing.com, Magicbricks, 99Acres, and Olx, and automations to get rid of repetitive tasks.",
         },
    ]
}

export default FAQData;